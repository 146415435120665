import * as Sentry from '@sentry/react';
import MovideskChat from 'components/movidesk/movidesk-chat';
import {
    CREDIT_FLOW_URL,
    GIRO_PAYROLL_LOAN_ADMIN,
    INVITE_REDICT_URL,
    PRIVACY_POLICY_FULL_TERM_URL,
    TERMS_AND_CONDITIONS_FULL_TERM_URL,
} from 'config/constants';
import AcceptInvite from 'features/accept-invite/accept-invite';
import AcceptTermsOfUse from 'features/accept-terms-of-use/accept-terms-of-use';
import AnticipationReviewLocation from 'features/anticipation/anticipation/anticipation-review';
import Dashboard from 'features/dashboard/dashboard';
import EmployeeBorrower from 'features/employee-borrower/employee-borrower';
import FirstAccess from 'features/first-access/first-access';
import { FooterType } from 'features/footer/footer';
import ForgotPassword from 'features/forgot-password/forgot-password';
import { HeaderType } from 'features/header/header';
import EmployeeProposesHistory from 'features/history-proposes/history-proposes';
import { Login } from 'features/login/login';
import PasswordReset from 'features/password-reset/password-reset';
import Profile from 'features/profile/profile';
import fecharSvg from 'images/fechar.svg';
import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PageNotFound from 'shared/error/page-not-found';
import ExternalRoute from 'shared/routes/external-route';
import PrivateRoute from 'shared/routes/private-route';
import PublicRoute from 'shared/routes/public-route';
export interface AppRoutesProps {
    basename: string;
}

const AppRoutes = (props: AppRoutesProps) => {
    return (
        <Sentry.ErrorBoundary>
            <BrowserRouter basename={props.basename}>
                <ToastContainer
                    position={toast.POSITION.BOTTOM_CENTER}
                    className="toastify-container"
                    toastClassName="toastify-toast"
                    hideProgressBar
                    pauseOnHover
                    closeButton={<img src={fecharSvg} alt="fechar" className="button__style--close" />}
                />
                <Switch>
                    <PublicRoute exact path="/" component={Login} headerType={HeaderType.NONE} footerType={FooterType.NONE} />
                    {/* <PublicRoute exact path="/webapp" component={() => <Redirect to="/" />} />
                    <PublicRoute exact path="/accept-invite/:token" component={AcceptInvite} />
                    <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
                    <PublicRoute exact path="/primeiro-acesso" component={FirstAccess} />
                    <PublicRoute exact path="/password-reset/:key" component={PasswordReset} />

                    <PrivateRoute exact path="/profile" component={Profile} />

                    <PrivateRoute exact path="/accept-terms-of-use" component={AcceptTermsOfUse} headerType={HeaderType.SIMPLE} />

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/anticipation/review" component={AnticipationReviewLocation} />
                    <PrivateRoute exact path="/employee-borrower/payroll-loan" component={EmployeeBorrower} />

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />

                    <PrivateRoute exact path="/proposes/history" component={EmployeeProposesHistory} />

                    <PrivateRoute exact path="/employee-borrower/payroll-loan/:proposeId" component={EmployeeBorrower} />

                    <ExternalRoute exact path="/invite-redirect" externalUrl={INVITE_REDICT_URL} />
                    <ExternalRoute exact path="/credit-flow" externalUrl={CREDIT_FLOW_URL} />

                    <ExternalRoute exact path="/payroll-loan-admin" externalUrl={GIRO_PAYROLL_LOAN_ADMIN} />

                    <ExternalRoute exact path="/full-terms" externalUrl={TERMS_AND_CONDITIONS_FULL_TERM_URL} />

                    <ExternalRoute exact path="/full-privacy" externalUrl={PRIVACY_POLICY_FULL_TERM_URL} /> */}

                    <PublicRoute component={PageNotFound} />
                </Switch>
                <MovideskChat />
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    );
};

export default AppRoutes;
