import { GIRO_PHONE_CONTACT } from 'config/constants';
import Carousel from 'features/login/components/carousel/carousel';
import { validateEmail, validatePassword } from 'features/login/validation-constants';
import { Authentication } from 'model/authentication';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from 'model/error';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { customizeErrorHandling } from 'reducer/application/error/actions';
import { loginRequest } from 'reducer/authentication/actions';
import { useAuthenticationState, useRootDispatch, useTermsOfUseState, useUserAccountState } from 'reducer/hooks';
import './user-login.scss';

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.notfound': { message: { key: 'login.invalid' } },
            'error.invalidpassword': { message: { key: 'login.invalid' } },
            'error.user.notactive': {
                type: ErrorType.BLOCKING,
                message: { key: GiroWebappErrorConstants.USER_NOT_ACTIVATED, options: { contact_number: GIRO_PHONE_CONTACT } },
                action: { noAction: true },
            },
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);
};

const useIsLoading = () => {
    const authenticationState = useAuthenticationState();
    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();

    const [isLoading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (authenticationState.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (authenticationState.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [authenticationState.status]);

    React.useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (userAccountState.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [userAccountState.status]);

    React.useEffect(() => {
        if (termsOfUseState.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (termsOfUseState.status === HttpRequestStatus.ERROR || termsOfUseState.status === HttpRequestStatus.SUCCESS) {
            setLoading(false);
        }
    }, [termsOfUseState.status]);

    return isLoading;
};

export const UserLogin = () => {
    const { t } = useTranslation();

    const [email, setEmail] = React.useState<string>('');
    const [password, setPasswrod] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const dispatch = useRootDispatch();

    useErrorConfiguration();

    const isLoading = useIsLoading();

    const haltProgression = !validateEmail(email).isValid || !validatePassword(password).isValid;

    const handleEnter = () => {
        setValidation(true);
        if (haltProgression) return;

        const _password = password;
        const request: Authentication = { username: email, password: _password };
        dispatch(loginRequest(request));
    };

    return (
        <div className="login--root">
            <Row className="row--container">
                <Col md="6" className="logo--container">
                    <div className="login--logo" />
                    <Carousel />
                </Col>

                <Col className="col--inputs">
                    <Row sm="12">
                        <Col md={{ size: 6, offset: 3 }} sm="12">
                            <div className="login--container">
                                <div style={{ marginBottom: '30px' }} />
                                <div className="login--message">
                                    Este portal foi desativado a partir de 18/03. Em caso de dúvidas, entre em contato com o RH da sua
                                    empresa pelo e-mail: operacoes@britains.com.br.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default UserLogin;
